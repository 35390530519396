<template>
<div>
   <el-breadcrumb class="breadcrumb-title" separator="/">
            <el-breadcrumb-item>费用中心</el-breadcrumb-item>
            <el-breadcrumb-item>账单管理</el-breadcrumb-item>
        </el-breadcrumb>
  <div class="bill-list-content flexcolumn-row-center">
    <div class="top-wrapper flexrow-column-center">
      <div class="top-left-wrapper flex-column flex-content-between">
        <span class="font-size-16" style="color:black;margin-left:1.5rem;">账户余额</span>
        <div class="flexrow-column-center">
          <span class="font-size-30" style="color:black;margin-left:1.5rem">{{'¥' + financeInfo.balance}}</span>
          <div class="charge-btn font-size-14 flex-all-center pointer" @click="onClickCharge">充值</div>
        </div>
      </div>
      <div class="top-right-wrapper flex-column flex-content-between">
        <span class="font-size-16" style="color:black;margin-left:3rem;">{{'可用额度：¥' + financeInfo.balance}}</span>
        <div class="flexrow-column-center">
          <span class="font-size-16" style="color:black;margin-left:3rem;margin-right:0.625rem">可用额度预警：</span>
          <el-switch v-model="switchState"
            active-color="#3468FE"
            @change='switchChange'>
          </el-switch>
          <span class="font-size-16 font-opacity-45" style="margin-left:1.5rem">{{'（ 预警阈值：¥' + financeInfo.balance_waring_price}}</span>
          <span class="font-size-16 font-opacity-45 pointer" 
            style="margin-left:0.3rem;color:#3468FE"
            @click="onClickChange">修改</span>
          <span class="font-size-16 font-opacity-45" style="margin-left:0.3rem">）</span>
        </div>
      </div>
    </div>
    <span class="font-size-16 font-313131 flex-start" style="margin-top:1.5rem">计费说明：</span>
    <span class="font-size-14 font-313131 flex-start" style="margin-top:1rem">1、存储空间按日使用峰值计算费用，举例说明当日存储用量峰值为100.00GB，存储单价为0.08元/GB/天，则当日存储空间费用=100.00*0.08=8.00元。</span>
    <span class="font-size-14 font-313131 flex-start" style="margin-top:1rem">2、流量按日总消耗量计算费用，举例说明当日流量总消耗量为50.00GB，流量单价为0.8元/GB，则当日流量费用=50.00 * 0.8=40.00元。</span>
    <div class="list-wrapper flexcolumn-row-center">
      <span class="font-size-16 title-text" style="width:95%;margin:1.25rem 0 1.25rem 0">账单列表</span>
      <div class="flexrow-column-center search-wrapper flexrow-column-center">
        <span class="font-size-14 search-title-text">账期：</span>
        <el-date-picker
          v-model="dateValue"
          type="month"
          placeholder="选择月"
          :clearable="false"
          :picker-options="pickerOptions">
        </el-date-picker>
        <div class="font-size-16 title-text filter-cost-tips">
          总费用：￥<span>{{costTips}}</span>
        </div>
      </div>
      <div class="search-wrapper flex-row-space-between">
        <div class="flexrow-column-center search-box">
          <span class="font-size-14 search-title-text">产品：</span>
          <SearchDropDown class="search-input"
            :options="bonusTypeList"
            :resetFlag="resetFlag"
            @dropDownSelect="bonusDropDownSelect"
            />
        </div>
        <div class="flexrow-column-center search-box">
          <span class="font-size-14 search-title-text">计费方式：</span>
          <SearchDropDown class="search-input"
            :options="chargeTypeList"
            :resetFlag="resetFlag"
            @dropDownSelect="chargeDropDownSelect"
            />
        </div>
        <div class="flexrow-column-center search-box">
          <span class="font-size-14 search-title-text">支付状态：</span>
          <SearchDropDown class="search-input"
            :options="payTypeList"
            :resetFlag="resetFlag"
            @dropDownSelect="payDropDownSelect"
            />
        </div>
        <div class="flexrow-column-center search-box">
          <span class="font-size-14 search-title-text">账单号：</span>
          <SearchInput class="search-input"
            placeholderStr="请输入账单号"
            :resetFlag="resetFlag"
            @updateSearchList="updateBillIdSearchList"
          />
        </div>
      </div>

      <div class="btns-wrppaer flexrow-column-center">
        <!-- <div class="flex-all-center font-size-14 pointer btn-wrapper"
          @click="onClickCheck">查询</div>
          -->
        <div class="flex-all-center font-size-14 pointer btn-wrapper"
          @click="onClickReset">重置</div>
      </div>
     <el-table :data="curList" border style="width: 100%" class="data-table">
      <el-table-column fixed align="center" prop="account_period" label="账期" width="120"></el-table-column>
      <el-table-column align="center" prop="account_number" label="账单号" width="200"> </el-table-column>
      <el-table-column align="center" prop="bonusStr" label="产品"> </el-table-column>
      <el-table-column align="center" prop="billingModelStr" label="计费方式"> </el-table-column>
      <el-table-column align="center" prop="begin_time" label="开始时间" width="150"> </el-table-column>
      <el-table-column align="center" prop="end_time" label="结束时间" width="150"> </el-table-column>
      <el-table-column align="center" prop="unitPrice" label="单价" width="120"> </el-table-column>
      <el-table-column align="center" prop="storage" label="用量"> </el-table-column>
      <el-table-column align="center" prop="amountPayable" label="应付金额"> </el-table-column>
      <el-table-column align="center" prop="payStatus" label="支付状态"> </el-table-column>
      <el-table-column align="center" prop="arrears" label="欠费金额"> </el-table-column>
    </el-table>
    </div>
         <el-pagination
      @current-change="handleCurrentChange"
      :current-page="curPage"
      :page-size="pageSize"
      :page-sizes="[pageSize]"
      layout="prev, pager, next, sizes, jumper , total"
      :background="true"
      :total="searchList.length">
    </el-pagination>
    <WaringPrice v-if="isShowWaringPrice" 
      @onCancel='onWaringPriceCancel' 
      @onConfirm='onWaringPriceConfirm'
      />
  </div>
</div>
</template>

<script>
import request from "@request/api/index";
import SearchInput from '@components/SearchInput';
import SearchDropDown from "@components/SearchDropDown";
import {getFormatDateByYM} from '@utils/dateUtil.js'
import WaringPrice from './components/WaringPrice'

export default {
  name: "BillList",
  data() {
    return {
      curList:[],
      curPage:1,
      pageSize: 10,
      resetFlag: 0,//重置表单组件信号码
      isSearch: true,//是否进行搜索
      switchState:false,
      billIdSearchInputText:"",//账单号搜索字段
      bonusType:"",//产品类型搜索字段
      chargeType:"",//计费类型类型搜索字段
      payType:"",//交易类型搜索字段
      itemList: [], //收支明细列表
      searchList:[],//过滤后的收支明细列表
      titleList:[{key:'account_period',value:'账期'},
        {key:'account_number',value:'账单号'},
        {key:'bonusStr',value:'产品'},
        {key:'billingModelStr',value:'计费方式'},
        {key:'begin_time',value:'开始时间'},
        {key:'end_time',value:'结束时间'},
        {key:'unitPrice',value:'单价'},
        {key:'storage',value:'用量'},
        {key:'amountPayable',value:'应付金额'},
        {key:'payStatus',value:'支付状态'},
        {key:'arrears',value:'欠费金额'}],
      bonusTypeList:[{key:'', value:'全部'},
        {key:1, value:'存储空间'},
        {key:2, value:'流量'}],
      chargeTypeList:[{key:'', value:'全部'},
        {key:1, value:'后付费'}],
      payTypeList:[{key:'', value:'全部'},
        {key:1, value:'未结清'},
        {key:2, value:'已支付'}],
      dateValue:'',
      costTips:'0.00',
      pickerOptions: {
        disabledDate: (time) => {
          return time.getTime() > Date.now()
        }
      },
      isShowWaringPrice:false,//是否显示预警阈值弹窗
      dialogOrigin:''
    };
  },
  computed:{
    financeInfo(){
      return this.$store.state.user.financeInfo;
    }
  },
  watch:{
    dateValue(){
      if(this.dateValue){
        let date = getFormatDateByYM(new Date(this.dateValue).getTime());
        this.requestBillList({date});
      }else{
        this.requestBillList({date:''});
      }
    },
    financeInfo(){
      this.switchState = this.financeInfo.balance_waring === 1;
    },
    searchList(){
      let page = Math.floor(this.searchList.length / this.pageSize) + 1;
      if(this.searchList.length % this.pageSize == 0){
        page--;
      }
      if(this.curPage > 1 && this.curPage > page){
        this.curPage = page;
      }
      this.computeList();
    }
  },
  created(){
    let date = getFormatDateByYM(new Date().getTime());
    this.dateValue = date;
    this.requestFinanceInfo();
  },
  methods: {
    computeList(){
      let start = (this.curPage - 1) * this.pageSize;
      let end = start + this.pageSize;
      this.curList = this.searchList.slice(start,end);
    },
    handleCurrentChange(page){
      this.curPage = page;
      this.computeList();
    },
    requestFinanceInfo(){
      request.bill.requestFinanceInfo();
    },
    async requestFinanceSet(params){
      return await request.bill.requestFinanceSet(params);
    },
    requestBillList(params){
      request.bill.requestBillList(params).then(res=>{
        let cost = 0;
        res.forEach(item=>{
          cost+=parseFloat(item.amountPayable.replace(/¥/i,''))
        });
        this.costTips = cost.toFixed(2);
        this.itemList = res;
        this.filterData();
      }).catch(err=>{
        console.log(err);
      });
    },
    /**
     * 账单编号搜索结果回调
     */
    updateBillIdSearchList(str){
      this.billIdSearchInputText = str;
      if(!this.isSearch) return;
      this.filterData();
    },
    /**
     * 收支类型选择回调
     */
    bonusDropDownSelect(id){
      this.bonusType = id;
      if(!this.isSearch) return;
      this.filterData();
    },
    /**
     * 计费方式类型选择回调
     */
    chargeDropDownSelect(id){
      this.chargeType = id;
      if(!this.isSearch) return;
      this.filterData();
    },
    /**
     * 交易类型选择回调
     */
    payDropDownSelect(id){
      this.payType = id;
      if(!this.isSearch) return;
      this.filterData();
    },
    /**
     * 开关预警
     */
     switchChange(){
      const state = this.switchState ? '开启' : '关闭';
      let _self = this;
      const balance_waring_price = _self.financeInfo.balance_waring_price ;
      if( _self.switchState){
         _self.onClickChange('switch');
        return false;
      }

      this.$confirm(`确认${state}可用额度预警吗？`, '确认操作', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then( async () => {
         const {code} =  await _self.requestFinanceSet({balance_waring:_self.switchState ? 1 : 0, balance_waring_price:parseFloat(balance_waring_price)});
        _self.$message({
            type: code==1000?'success':'error',
            message:code==1000?`${state}成功！`:'error',
        })
        if(code!=1000){ _self.switchState= !_self.switchState}
     
      }).catch(() => {
        _self.switchState= !_self.switchState
        this.$message({
          type: 'info',
          message: '已取消操作'
        });
      });;
      
     
    },
    /**
     * 修改预警阈值按钮点击
     */
    onClickChange(name){
      this.isShowWaringPrice = true;
      this.dialogOrigin = name
    },
    /**
     * 充值按钮点击
     */
    onClickCharge(){
      this.$router.push({ name: 'charge'});
    },
    onWaringPriceCancel(){
      this.isShowWaringPrice = false;
      if(this.dialogOrigin === 'switch'){
       this.switchState = !this.switchState;
      }
    },
    async onWaringPriceConfirm(data){
      const {code} = await this.requestFinanceSet({balance_waring:this.switchState ? 1 : 0, balance_waring_price:parseFloat(data).toFixed(2)});

        this.$message({
          type: code==1000?'success':'error',
          message:code==1000?'预警阈值设置成功！':'error',
        });
      this.isShowWaringPrice = false;
    },
    onClickReset(){
      this.resetFlag++;
      this.isSearch = false;
      let date = getFormatDateByYM(new Date().getTime());
      if(this.dateValue === date){
        this.$nextTick(()=>{
          this.filterData(10086);
        })
      }
      this.dateValue = date;
    },
    /**
     * 数据过滤
     */
    filterData(){
      let copyList = JSON.parse(JSON.stringify(this.itemList));
      let arr = [];
      for(let i = 0;i<copyList.length;i++){
        if(copyList[i].account_number.indexOf(this.billIdSearchInputText) >= 0 && 
          (this.bonusType === '' || copyList[i].product_type == this.bonusType) && 
          (this.chargeType === '' || copyList[i].billing_model == this.chargeType) && 
          (this.payType === '' || copyList[i].pay_status == this.payType)){
          arr.push(copyList[i]);
        }
      }
      this.searchList = arr;
      this.isSearch = true;
    }
  },
  components:{
    SearchInput, SearchDropDown, WaringPrice
  }
};
</script>

<style lang="scss" scoped>
.bill-list-content {
  width: 100%;
  min-width: 1184px;
  position: relative;
  height: 100%;
  .top-wrapper{
    width: 100%;
    min-width: 1184px;
    height: 136px;
    background: #FFFFFF;
    border-radius: 8px;
    flex-shrink: 0;
    .top-left-wrapper{
      width:40%;
      height:88px;
      border-right:1px solid #E8E8E8;
      .charge-btn{
        width: 80px;
        height: 32px;
        background: #3468FE;
        border-radius: 4px;
        color: white;
        margin-left: 30px;
      }
    }
    .top-right-wrapper{
      width:60%;
      height:88px;
    }
  }
  .filter-cost-tips{
    margin-left: 1rem;;
  }
  .list-wrapper{
    margin-top: 20px;
    flex:1;
    width: 100%;
    min-height: 900px;
    min-width: 1184px;
    background: #FFFFFF;
    border-radius: 8px;
    .title-text{
      color: black;
      font-weight: 600;
    }
    .search-wrapper{
      width:95%;
      height:auto;
      min-width: 1120px;
      flex-wrap: wrap;
      margin-bottom: 20px;
      .search-box{
        width:23%;
        min-width:257px;
        .search-title-text{
          color: black;
          max-width: 80px;
          white-space:nowrap;
        }
        .search-input{
          width:74%;
          min-width:177px;
          height:32px;
        }
      }
    }
    .btns-wrppaer{
      margin-top: 16px;
      margin-bottom: 20px;
      width:95%;
      .btn-wrapper{
        margin-right: 16px;
        width: 80px;
        height: 36px;
        background: #3468FE;
        border-radius: 4px;
        border: 1px solid #3468FE;
        color: white;
      }
    }
    .pagination-list-wrapper{
      position: relative;
      flex:1;
      width:95%;
      min-width: 1120px;
    }
  }
  ::v-deep .el-input__icon,
  ::v-deep .el-range-separator{
    line-height: 32px;
  }
  ::v-deep .el-input__inner{
    height: 32px;
    line-height: 32px;
  }
  ::v-deep .el-switch__core{
    width:42px;
    height:22px;
    border-radius:11px;
  }
  ::v-deep .el-switch__core::after{
    width:18px;
    height:18px;
  }
}

::v-deep.data-table {
  td .cell {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
    white-space:nowrap;
  }
  &.el-table th {
    background: #f8f9fb;

    .cell {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.85);
      font-weight: 500;
    }
  }
}
.el-table--border::after{
  width: 0;
}
.el-table--border{
  border:none
}
.el-table::before {
  width: 0;
}
</style>