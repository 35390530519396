

<template>
  <el-dialog title="预警设置" :visible="true" width="36%" :before-close="handleClose">
    <div class="des-wrapper font-size-14 font-black">当账户可用余额小于该值时，每天短信（邮件）提醒一次，最多连续提醒5天。</div>
    <el-form :model="ruleForm" ref="ruleForm" >
      <el-form-item  prop="value" label="预警阈值：" :label-width="formLabelWidth" :rules="[
      { required: true, message: '阈值不能为空'},
      { type: 'number', message: '阈值必须为数字值'},
      { pattern: /^([1-9]\d{0,6}|10000000)$/, message: '范围1~10000000'},
    ]">
        <el-input type="value" v-model.number="ruleForm.value"  placeholder="范围为 1 ~ 10000000"
         autocomplete="off" style="width:100%"  ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="onCancel">取 消</el-button>
      <el-button class="buttonColor"type="primary" @click="onConfirm('ruleForm')">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialogFormVisible: false,
       ruleForm: {
          value: '',
        },
        rules: {
          value: [
            { required: true, message: '请输入活动名称', trigger: 'blur' },
            { min: 1, max: 0, message: '长度在 3 到 5 个字符', trigger: 'blur' }
          ]}
        ,
      formLabelWidth: '120px'
    };
  },
  methods: {
    handleClose(){
      this.$emit('onCancel');
    },
    onCancel(){
      this.$emit('onCancel');
    },
    onConfirm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
             this.$emit('onConfirm', this.ruleForm.value);
          } 
        });
      },
  }
};
</script>
<style lang="scss" scoped>
.des-wrapper {
  width: 100%;
  border: 1px solid lightskyblue;
  background: lightcyan;
  margin-bottom: 32px;
  line-height: 40px;
  padding-left: 8px;
}
</style>
